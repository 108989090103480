import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/layout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Content = makeShortcode("Content");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Content mdxType="Content">
      <p>{`V sobotu 8. 2. vám lyžovačku v stredisku Studničky spríjemnia remeselné trhy. Čakajú vás:`}</p>
      <ul>
        <li parentName="ul">{`stánky plné krásnych handmade vecičiek`}</li>
        <li parentName="ul">{`domáce “vernárske“ sladké dobroty náš “Studničky” punč`}</li>
        <li parentName="ul">{`maľovanie na tvár pre detičky (aj rodičov)`}</li>
        <li parentName="ul">{`dopoludnie spríjemní svojou hudbou harmonikár`}</li>
      </ul>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      